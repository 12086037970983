import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AuthStoreState = {
  jwt?: string;
  email?: string;
  setJwt: (jwt?: string) => void;
  setEmail: (email?: string) => void;
};

const DEFAULT_STATE: Pick<AuthStoreState, 'jwt' | 'email'> = {
  jwt: undefined,
  email: undefined,
};

export const useAuthStore = create<AuthStoreState>()(
  persist(
    (set) => ({
      ...DEFAULT_STATE,
      setJwt: (jwt) => {
        set((state) => ({ ...state, jwt }));
      },
      setEmail: (email) => {
        set((state) => ({ ...state, email }));
      },
    }),
    {
      name: 'studio-auth',
    },
  ),
);
