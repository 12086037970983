import { useNavigation } from '@refinedev/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

export const RedirectTo: React.FC<{ path: string }> = ({ path }) => {
  const params = useParams();
  const navigation = useNavigation();

  useEffect(() => {
    navigation.replace(
      path.replace(/:\w+/g, (match) => params[match.slice(1)] ?? ''),
    );
  }, [params, navigation]);

  return null;
};
