import { EpisodeStatus } from '@maestro/cms/resources/episode/components/EpisodeStatus';
import { useEpisodeStatus } from '@maestro/cms/resources/episode/hooks/useEpisodeStatus';
import { Breadcrumb, TopBar } from '@maestro/components';
import { ShareButton } from '@maestro/components/ShareButton/ShareButton';
import { breakpoints } from '@maestro/styles';
import { useNavigation } from '@refinedev/core';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { EditEpisodeButton } from '../components/episode/EditEpisode/EditEpisodeButton';
import { EditSeriesButton } from '../components/series/EditSeries/EditSeriesButton';
import { useStudioFlowStore } from '../hooks/useStudioFlowStore';
import { PlayEpisodeButton } from './PlayEpisodeButton';
import { StudioSaveStatus } from './StudioSaveStatus';
import { SuggestionsButton } from './SuggestionsButton';

export const StudioTopBar: React.FC = () => {
  const navigation = useNavigation();
  const { id: episodeId } = useParams();
  const { seriesId, seriesTitle, episodeName } = useStudioFlowStore();

  const {
    hasNewVersion,
    isDraft,
    isPublished,
    publishedAt,
    episodeOrder,
    refresh: refreshEpisodeStatus,
  } = useEpisodeStatus(episodeId!);

  const breadcrumb = [
    {
      label: (
        <EditSeriesButton
          episodeId={episodeId!}
          label={seriesTitle ?? ''}
          seriesId={seriesId!}
        />
      ),
    },
    {
      label: (
        <EditEpisodeButton
          episodeId={episodeId!}
          label={`E${episodeOrder ?? ''}: ${episodeName}`}
        />
      ),
    },
  ];

  return (
    <TopBar
      logoConfig={{
        onLogoClick: () => navigation.push(`/series/${seriesId}`),
        clickLocation: 'My Studio',
      }}
      breadcrumb={
        <HideOnMobile>
          <Breadcrumb
            breadcrumb={breadcrumb}
            additionalItems={
              <EpisodeStatus
                hasNewVersion={hasNewVersion}
                isDraft={isDraft}
                isPublished={isPublished}
                publishedAt={publishedAt}
                displayPublished={false}
              />
            }
          />
        </HideOnMobile>
      }
    >
      <StudioSaveStatus refreshStatus={refreshEpisodeStatus} />
      <SuggestionsButton />
      {!!episodeId && (
        <ShareButton
          episodeId={episodeId}
          canPublish={hasNewVersion || isDraft}
          variant="studioTopBar"
        />
      )}

      <PlayEpisodeButton />
    </TopBar>
  );
};

const HideOnMobile = styled.div`
  @media ${breakpoints.tablet} {
    display: none;
  }
`;
