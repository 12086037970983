import { useMaestroToast } from '@maestro/components';
import {
  StoryFactoryBranchFragment,
  useGetStoryFactoryBranchQuery,
  useUpdateStoryFactoryBranchMutation,
} from '@maestro/graphql';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { NotFound } from '../../../components/NotFound';
import { TableLoader } from '../../../components/TableLoader';
import { StoryFactoryBranchForm } from './StoryFactoryBranchForm';

export const StoryFactoryBranchEdit: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetStoryFactoryBranchQuery({ id: id! });
  const { mutateAsync: updateBranch } = useUpdateStoryFactoryBranchMutation();
  const [isSaving, setSaving] = useState(false);
  const toast = useMaestroToast();
  const navigate = useNavigate();

  const save = async (
    factory: Pick<
      StoryFactoryBranchFragment,
      'fields' | 'title' | 'coverImage' | 'category'
    >,
  ) => {
    const { coverImage, fields, title, category } = factory;

    setSaving(true);
    try {
      await updateBranch({
        id: id!,
        input: { coverImage, fields, title, categoryId: category?.id },
      });
      toast({ title: 'Story saved', status: 'success' });
    } finally {
      setSaving(false);
    }
  };

  if (isLoading) {
    return <TableLoader />;
  } else if (!data?.storyFactoryBranch) {
    return <NotFound />;
  }

  return (
    <StoryFactoryBranchForm
      formTitle="Editing Story"
      fieldDefinition={data.storyFactoryBranch.storyFactory.fields}
      title={data.storyFactoryBranch.title}
      coverImage={data.storyFactoryBranch.coverImage}
      category={data.storyFactoryBranch.category}
      fields={data.storyFactoryBranch.fields}
      onSave={save}
      onCancel={() => navigate(`/story/${id}`)}
      isSaving={isSaving}
    />
  );
};
