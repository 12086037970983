import { useAnalytics } from '@maestro/monitoring';
import { initializeSentry } from '@maestro/monitoring/sentry';
import { GlobalStyle } from '@maestro/styles/theme/globalStyles';
import { MaestroThemeProvider } from '@maestro/styles/theme/Provider';
import { toSupabaseClient } from '@maestro/supabase';
import { notificationProvider } from '@refinedev/chakra-ui';
import { Refine } from '@refinedev/core';
import routerProvider, {
  UnsavedChangesNotifier,
} from '@refinedev/react-router';
import { dataProvider, liveProvider } from '@refinedev/supabase';
import { AppProviders } from './AppProviders';
import { authProvider } from './auth';
import { MobileLanding } from './resources/auth/MobileLanding';
import { MaestroRoutes } from './Routes';

initializeSentry();

export const Cms = () => {
  useAnalytics();

  return (
    <AppProviders>
      <MaestroThemeProvider>
        <GlobalStyle />
        <Refine
          authProvider={authProvider}
          routerProvider={routerProvider}
          notificationProvider={notificationProvider}
          dataProvider={dataProvider(toSupabaseClient())}
          liveProvider={liveProvider(toSupabaseClient())}
          resources={[
            {
              name: 'world',
              list: '/world',
              create: '/world/create',
              edit: '/world/:id/edit',
              show: '/world/:id',
              meta: {
                canDelete: false,
                label: 'All Worlds',
              },
            },
            {
              name: 'item',
              list: '/items',
              create: '/items/create',
              edit: '/items/:id/edit',
              meta: {
                canDelete: false,
                label: 'Items',
              },
            },
            {
              name: 'series',
              list: '/series',
              create: '/series/create',
              edit: '/series/:id/edit',
              show: '/series/:id',
              meta: {
                canDelete: true,
                label: 'My Series',
              },
            },
            {
              name: 'episode',
              list: '/series/:seriesId/episode',
              create: '/series/:seriesId/episode/create',
              edit: '/series/:seriesId/episode/:id/edit',
              show: '/series/:seriesId/episode/:id',
              meta: {
                canDelete: true,
                hide: true,
                parent: 'series',
                label: 'Episodes',
              },
            },
          ]}
          options={{
            syncWithLocation: true,
            warnWhenUnsavedChanges: true,
          }}
        >
          <MobileLanding />
          <MaestroRoutes />
          <UnsavedChangesNotifier />
        </Refine>
      </MaestroThemeProvider>
    </AppProviders>
  );
};
