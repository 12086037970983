import { Button } from '@chakra-ui/react';
import {
  HeaderContainer,
  HeaderRowContainer,
  HeaderTitle,
  Menu,
  MenuDropdownButton,
  MenuItem,
  MenuList,
  ListBody,
  ListHeader,
  ListHeaderColumn,
  ListItem,
  ListItemColumn,
  ScreenContainer,
  SectionCard,
  SectionContainer,
  SectionTitle,
  TextInput,
  List,
} from '@maestro/components';
import { FieldDefinition, StoryFactory } from '@maestro/graphql';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddFieldButton } from './AddFieldButton';
import { FieldsList } from './FieldsList';

type Props = {
  formTitle: string;
  isSaving?: boolean;
  title?: string;
  promptSlug?: string;
  factoryStrategy?: string;
  twoCharacterPromptSlug?: string;
  sceneDirectorPromptSlug?: string;
  creativeDirectorPromptSlug?: string;
  fields?: FieldDefinition[];
  onSave: (
    value: Pick<
      StoryFactory,
      | 'fields'
      | 'title'
      | 'promptSlug'
      | 'twoCharacterPromptSlug'
      | 'sceneDirectorPromptSlug'
      | 'creativeDirectorPromptSlug'
      | 'factoryStrategy'
    >,
  ) => void;
  onCancel: () => void;
};

export const StoryFactoryForm: React.FC<Props> = (props) => {
  const [title, setTitle] = useState('');
  const [promptSlug, setPromptSlug] = useState('');
  const [twoCharacterPromptSlug, setTwoCharacterPromptSlug] = useState('');
  const [sceneDirectorPromptSlug, setSceneDirectorPromptSlug] = useState('');
  const [creativeDirectorPromptSlug, setCreativeDirectorPromptSlug] =
    useState('');
  const [factoryStrategy, setFactoryStrategy] = useState('');
  const [fields, setFields] = useState<FieldDefinition[]>([]);
  const [isDirty, setDirty] = useState(false);
  const canSave =
    !!title &&
    !!promptSlug &&
    !!twoCharacterPromptSlug &&
    !!sceneDirectorPromptSlug &&
    !!creativeDirectorPromptSlug;

  useEffect(() => {
    setTitle(props.title ?? '');
    setDirty(false);
  }, [props.title]);

  useEffect(() => {
    setPromptSlug(props.promptSlug ?? '');
    setDirty(false);
  }, [props.promptSlug]);

  useEffect(() => {
    setTwoCharacterPromptSlug(props.twoCharacterPromptSlug ?? '');
    setDirty(false);
  }, [props.twoCharacterPromptSlug]);

  useEffect(() => {
    setSceneDirectorPromptSlug(props.sceneDirectorPromptSlug ?? '');
    setDirty(false);
  }, [props.sceneDirectorPromptSlug]);

  useEffect(() => {
    setCreativeDirectorPromptSlug(props.creativeDirectorPromptSlug ?? '');
    setDirty(false);
  }, [props.creativeDirectorPromptSlug]);

  useEffect(() => {
    setFields(props.fields ?? []);
    setDirty(false);
  }, [props.fields]);

  useEffect(() => {
    setFactoryStrategy(props.factoryStrategy ?? 'v2');
    setDirty(false);
  }, [props.factoryStrategy]);

  const onFieldAdded = (field: FieldDefinition) => {
    setFields([...fields, field]);
    setDirty(true);
  };

  const onFieldRemoved = (field: FieldDefinition) => {
    setFields((fields) => fields.filter(({ id }) => id !== field.id));
    setDirty(true);
  };

  const onFieldChanged = (field: FieldDefinition, index: number) => {
    setFields((fields) => fields.map((f, idx) => (index === idx ? field : f)));
    setDirty(true);
  };

  const onPromptSlugChanged = (promptSlug: string) => {
    setPromptSlug(promptSlug);
    setDirty(true);
  };

  const onTwoCharacterPromptSlugChanged = (promptSlug: string) => {
    setTwoCharacterPromptSlug(promptSlug);
    setDirty(true);
  };

  const onSceneDirectorPromptSlugChanged = (promptSlug: string) => {
    setSceneDirectorPromptSlug(promptSlug);
    setDirty(true);
  };

  const onCreativeDirectorPromptSlugChanged = (promptSlug: string) => {
    setCreativeDirectorPromptSlug(promptSlug);
    setDirty(true);
  };

  const onTitleChanged = (title: string) => {
    setTitle(title);
    setDirty(true);
  };

  const onFactoryStrategyChanged = (factoryStrategy: string) => {
    setFactoryStrategy(factoryStrategy);
    setDirty(true);
  };

  const onSave = () => {
    props.onSave({
      fields,
      title,
      promptSlug,
      factoryStrategy,
      twoCharacterPromptSlug,
      sceneDirectorPromptSlug,
      creativeDirectorPromptSlug,
    });
    setDirty(false);
  };

  return (
    <ScreenContainer>
      <HeaderContainer>
        <StyledHeaderRowContainer>
          <HeaderTitle>{props.formTitle}</HeaderTitle>
          <Divider />
          <Button variant="default" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            isLoading={props.isSaving}
            onClick={onSave}
            isDisabled={!isDirty && !canSave}
          >
            Save
          </Button>
        </StyledHeaderRowContainer>
      </HeaderContainer>

      <SectionContainer>
        <SectionTitle>Title</SectionTitle>
        <SectionCard>
          <FormComponent>
            <TextInput
              isInvalid={!title}
              value={title}
              onChange={(evt) => onTitleChanged(evt.target.value)}
            />
          </FormComponent>
        </SectionCard>

        <SectionTitle>Factory Strategy</SectionTitle>
        <SectionCard>
          <FormComponent>
            <Menu>
              <MenuDropdownButton>{factoryStrategy}</MenuDropdownButton>

              <MenuList>
                <MenuItem onClick={() => onFactoryStrategyChanged('v2')}>
                  V2
                </MenuItem>
                <MenuItem onClick={() => onFactoryStrategyChanged('v3')}>
                  V3
                </MenuItem>
              </MenuList>
            </Menu>
          </FormComponent>
        </SectionCard>

        <SectionTitle>Prompts</SectionTitle>
        <SectionCard>
          <List>
            <ListHeader>
              <ListHeaderColumn width="30%">Name</ListHeaderColumn>
              <ListHeaderColumn flex>Description</ListHeaderColumn>
            </ListHeader>
            <ListBody>
              <ListItem>
                <ListItemColumn width="30%">
                  One Character Prompt
                </ListItemColumn>
                <ListItemColumn flex disablePadding>
                  <TextInput
                    fullWidth
                    flex={1}
                    value={promptSlug}
                    onChange={(evt) => onPromptSlugChanged(evt.target.value)}
                  />
                </ListItemColumn>
              </ListItem>
              <ListItem>
                <ListItemColumn width="30%">
                  Two Character Prompt
                </ListItemColumn>
                <ListItemColumn flex disablePadding>
                  <TextInput
                    fullWidth
                    flex={1}
                    value={twoCharacterPromptSlug}
                    onChange={(evt) =>
                      onTwoCharacterPromptSlugChanged(evt.target.value)
                    }
                  />
                </ListItemColumn>
              </ListItem>
              {factoryStrategy === 'v3' && (
                <ListItem>
                  <ListItemColumn width="30%">
                    Scene Director Prompt
                  </ListItemColumn>
                  <ListItemColumn flex disablePadding>
                    <TextInput
                      fullWidth
                      flex={1}
                      value={sceneDirectorPromptSlug}
                      onChange={(evt) =>
                        onSceneDirectorPromptSlugChanged(evt.target.value)
                      }
                    />
                  </ListItemColumn>
                </ListItem>
              )}
              {factoryStrategy === 'v3' && (
                <ListItem>
                  <ListItemColumn width="30%">
                    Creative Director Prompt
                  </ListItemColumn>
                  <ListItemColumn flex disablePadding>
                    <TextInput
                      fullWidth
                      flex={1}
                      value={creativeDirectorPromptSlug}
                      onChange={(evt) =>
                        onCreativeDirectorPromptSlugChanged(evt.target.value)
                      }
                    />
                  </ListItemColumn>
                </ListItem>
              )}
            </ListBody>
          </List>
        </SectionCard>

        <SectionTitle>Form Fields</SectionTitle>
        <SectionCard>
          <FormComponent>
            <FieldsList
              fields={fields}
              onRemove={onFieldRemoved}
              onChange={onFieldChanged}
            />
            <AddFieldButton onAddField={onFieldAdded} />
          </FormComponent>
        </SectionCard>
      </SectionContainer>
    </ScreenContainer>
  );
};

const Divider = styled.div`
  flex: 1;
`;

const StyledHeaderRowContainer = styled(HeaderRowContainer)`
  align-items: center;
  width: 100%;
`;

const FormComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
