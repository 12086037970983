import {
  List,
  ListBody,
  ListHeader,
  ListHeaderColumn,
} from '@maestro/components';
import { FieldDefinition } from '@maestro/graphql';
import { dimensions } from '@maestro/styles';
import React, { useState } from 'react';
import { FieldForm } from './FieldForm';
import { FieldRow } from './FieldRow';

type Props = {
  fields: FieldDefinition[];
  onChange?: (data: FieldDefinition, index: number) => void;
  onRemove?: (data: FieldDefinition, index: number) => void;
};

export const FieldsList: React.FC<Props> = (props) => {
  const { fields, onChange, onRemove } = props;
  const [fieldIndex, setFieldIndex] = useState<number | undefined>();
  const [field, setField] = useState<FieldDefinition | undefined>();

  const onEdit = (field: FieldDefinition, index: number) => {
    setField(field);
    setFieldIndex(index);
  };

  const onClose = () => {
    setField(undefined);
    setFieldIndex(undefined);
  };
  const onSave = (field: FieldDefinition) => {
    onChange?.(field, fieldIndex!);
    onClose();
  };

  return (
    <>
      <FieldForm
        onChange={onSave}
        isOpen={!!field}
        onClose={onClose}
        data={field}
      />
      <List>
        <ListHeader>
          <ListHeaderColumn width="30%">Name</ListHeaderColumn>
          <ListHeaderColumn flex>Label</ListHeaderColumn>
          <ListHeaderColumn width={dimensions.size120} />
        </ListHeader>
        <ListBody>
          {fields.map((field, index) => (
            <FieldRow
              key={index}
              data={field}
              onRemove={onRemove ? () => onRemove?.(field, index) : undefined}
              onEdit={onChange ? () => onEdit?.(field, index) : undefined}
            />
          ))}
        </ListBody>
      </List>
    </>
  );
};
