import { Button, ModalFooter } from '@chakra-ui/react';
import { useAuthStore } from '@maestro/auth';
import { Icon, Modal, ModalBody, useMaestroToast } from '@maestro/components';
import {
  GetStoryFactoryBranchQuery,
  StoryFactoryBranchFragment,
  StoryStatus,
  useUpdateStoryFactoryBranchMutation,
} from '@maestro/graphql';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import styled from 'styled-components';

type Props = {
  type: 'publish' | 'unpublish';
  isOpen: boolean;
  onClose: () => void;
  story: StoryFactoryBranchFragment;
};

export const PublishStoryModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  const toast = useMaestroToast();
  const email = useAuthStore((state) => state.email);
  const queryClient = useQueryClient();
  const isPublishing = props.type === 'publish';
  const { mutate: updateBranch, isLoading } =
    useUpdateStoryFactoryBranchMutation();

  const onConfirm = () => {
    const reviewSubmittedAt = isPublishing ? new Date().toISOString() : null;
    const publishedAt = isPublishing ? new Date().toISOString() : null;
    const status = isPublishing ? StoryStatus.Published : StoryStatus.Private;
    const approvedByEmail = isPublishing
      ? (email ?? 'admin@tallylabs.xyz')
      : null;

    updateBranch(
      {
        id: props.story.id,
        input: { publishedAt, status, reviewSubmittedAt, approvedByEmail },
      },
      {
        onSuccess: (data) => {
          if (data.updateFactoryBranch.error) {
            toast({
              status: 'warning',
              title: 'Failed to update story',
            });
          } else {
            queryClient.setQueryData(
              ['getStoryFactoryBranch', { id: props.story.id }],
              (before) => {
                if (!before) {
                  return;
                }

                const value = before as GetStoryFactoryBranchQuery;

                return {
                  ...value,
                  storyFactoryBranch: {
                    ...value.storyFactoryBranch,
                    publishedAt,
                    status,
                    reviewSubmittedAt,
                    approvedByEmail,
                  },
                };
              },
            );
            onClose();

            toast({
              status: 'success',
              title: `Story ${props.type}ed successfully`,
            });
          }
        },
      },
    );
  };

  return (
    <Modal title="" isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalBody>
        <Container>
          <ContentColumn>
            <Title>{isPublishing ? 'Publish' : 'Unpublish'} story</Title>
            <Description>
              Are you sure you want to {props.type} this story?
            </Description>
          </ContentColumn>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={onConfirm}
          isLoading={isLoading}
          leftIcon={<Icon name="publish" size={rawDimensions.size16} />}
          variant={props.type === 'publish' ? 'primary' : 'danger'}
        >
          {props.type === 'publish' ? 'Publish' : 'Unpublish'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  gap: ${dimensions.size24};
  justify-content: space-around;
  align-items: center;
  padding: ${dimensions.size24};
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${dimensions.size12};
  flex: 1;
`;

const Title = styled.div`
  ${textStyles.title.t24b}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Description = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.header};
`;
