import { Button, Checkbox, ModalFooter } from '@chakra-ui/react';
import { Field, Icon, Modal, ModalBody, TextInput } from '@maestro/components';
import { FieldDefinition, FieldType } from '@maestro/graphql';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

type PropertyFormProps = {
  onChange: (data: FieldDefinition) => void;
  data?: FieldDefinition;
  isOpen: boolean;
  onClose: () => void;
};

const icons: Record<FieldType, string> = {
  [FieldType.Number]: 'number-type',
  [FieldType.Text]: 'text-type',
  [FieldType.Select]: 'enum-type',
};

export const FieldForm: React.FC<PropertyFormProps> = (props) => {
  const { isOpen, onClose } = props;
  const [localData, setLocalData] = useState(
    props.data ? { ...props.data } : undefined,
  );

  const isInvalidChoice = useMemo(() => {
    if (localData?.type !== FieldType.Select) {
      return false;
    }

    return localData.options?.some((option) => option === '');
  }, [localData]);

  const isInvalidValue = !localData?.id || !localData?.name || isInvalidChoice;
  const icon = localData ? icons[localData.type] : undefined;

  const onSave = () => {
    props.onChange(localData!);
    props.onClose();
  };

  useEffect(() => {
    setLocalData(props.data ? { ...props.data } : undefined);
  }, [props.data]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.isOpen && localData?.id === '') {
      setTimeout(() => ref.current?.querySelector('input')?.focus(), 300);
    }
  }, [props.isOpen, localData]);

  const onDeleteChoice = (index: number) => {
    if (localData) {
      setLocalData({
        ...localData,
        options: localData?.options?.filter((_, idx) => idx !== index) ?? [],
      });
    }
  };

  const onEditChoice = (value: string, index: number) => {
    if (localData) {
      setLocalData({
        ...localData,
        options:
          localData?.options?.map((item, idx) =>
            idx === index ? value : item,
          ) ?? [],
      });
    }
  };

  const onCreateChoice = () => {
    if (localData) {
      setLocalData({
        ...localData,
        options: [...(localData?.options ?? []), ''],
      });
    }
  };

  return (
    <Modal title="Edit field" isOpen={isOpen} onClose={onClose}>
      <ModalBody>
        {localData && (
          <DetailsContainer ref={ref}>
            <Field label="Name">
              <TextInput
                autoComplete="off"
                leftIcon={<Icon name={icon!} size={rawDimensions.size16} />}
                placeholder="Name"
                hint="Used in the prompt"
                maxCharacters={40}
                isInvalid={!localData.id}
                value={localData.id}
                onChange={(e) =>
                  setLocalData({ ...localData, id: e.target.value })
                }
              />
            </Field>
            <Field label="Label">
              <TextInput
                autoComplete="off"
                placeholder="Label"
                hint="This is going to show on the user form for the story generation"
                maxCharacters={40}
                isInvalid={!localData.name}
                value={localData.name}
                onChange={(e) =>
                  setLocalData({ ...localData, name: e.target.value })
                }
              />
            </Field>
            <Field>
              <Checkbox
                isChecked={localData.alwaysAsk ?? false}
                onChange={(e) =>
                  setLocalData({ ...localData, alwaysAsk: e.target.checked })
                }
              >
                <TextLabel>
                  Always ask this field, even if filled in parent story
                </TextLabel>
              </Checkbox>
            </Field>
            <Field label="Description">
              <TextInput
                autoComplete="off"
                placeholder="Description"
                hint="The description is going to show on the user form for the story generation"
                value={localData.description ?? ''}
                onChange={(e) =>
                  setLocalData({ ...localData, description: e.target.value })
                }
              />
            </Field>
            <Columns>
              {localData.options?.map((option, index) => (
                <TextInput
                  key={index}
                  isInvalid={!option}
                  rightElement={
                    index > 0 && (
                      <Button
                        variant="inputButton"
                        onClick={() => onDeleteChoice(index)}
                      >
                        <Icon name="trash" size={rawDimensions.size16} />
                      </Button>
                    )
                  }
                  placeholder="Name"
                  onChange={(e) => onEditChoice(e.target.value, index)}
                  value={option}
                />
              ))}
            </Columns>
          </DetailsContainer>
        )}
      </ModalBody>
      <ModalFooter>
        {!!localData?.options && (
          <Button
            variant="default"
            onClick={onCreateChoice}
            leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
          >
            Add choice
          </Button>
        )}
        <Divider />
        <Button variant="default" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave} isDisabled={isInvalidValue}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const DetailsContainer = styled.div`
  width: 100%;
  padding: ${dimensions.size16};
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;

const Divider = styled.div`
  flex: 1;
`;

const TextLabel = styled.span`
  ${textStyles.body.b14m}
`;
