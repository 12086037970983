import { env } from '@maestro/env';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router';

export const initializeSentry = () => {
  if (env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      environment: env.VITE_DEPLOYMENT_ENVIRONMENT,
      release: `maestro@${env.VITE_MAESTRO_VERSION}`,
      tracesSampleRate: 1.0,
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/([a-z0-9-]+[.])*downavenue[.](com|dev)/,
      ],
    });
  }
};

export const identify = (userId?: string, email?: string) => {
  Sentry.setUser({ id: userId, email });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
