import {
  CmsCard,
  HeaderContainer,
  HeaderRowContainer,
  HeaderTitle,
  Link,
  ScreenContainer,
  SectionContainer,
} from '@maestro/components';
import { useListStoryFactoryBranchQuery } from '@maestro/graphql';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { EmptyState } from '../../../components/EmptyState';
import { TableLoader } from '../../../components/TableLoader';
import { StoryStatus } from './StoryFactoryBranchStatus';

export const StoryFactoryBranchList = () => {
  const { data, isLoading } = useListStoryFactoryBranchQuery({
    filter: { isPublishedOrHasNoCreator: true },
  });
  const list = data?.storyFactoryBranches ?? [];

  return (
    <ScreenContainer>
      <HeaderContainer>
        <HeaderRowContainer>
          <HeaderTitle>Story Factory Branch</HeaderTitle>
        </HeaderRowContainer>
      </HeaderContainer>

      <SectionContainer>
        {isLoading && list.length === 0 ? (
          <TableLoader />
        ) : list.length > 0 ? (
          <StoryFactoryBranchListContainer>
            {list.map((item) => (
              <Link to={`/story/${item.id}`} key={item.id}>
                <CmsCard
                  image={item.coverImage}
                  aspectRatio={1.5}
                  title={item.title}
                  description={item.storyFactory.title}
                >
                  <StoryStatus story={item} />
                </CmsCard>
              </Link>
            ))}
          </StoryFactoryBranchListContainer>
        ) : (
          <EmptyState title="No story factory found." />
        )}
      </SectionContainer>
    </ScreenContainer>
  );
};

const StoryFactoryBranchListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${dimensions.size24};
  justify-content: start;
`;
