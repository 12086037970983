import { Button, ButtonGroup, List } from '@chakra-ui/react';
import {
  HeaderContainer,
  HeaderRowContainer,
  HeaderTitle,
  Link,
  ListBody,
  ListHeader,
  ListHeaderColumn,
  ListItem,
  ListItemColumn,
  ScreenContainer,
  SectionCard,
  SectionContainer,
  SectionTitle,
} from '@maestro/components';
import { useGetStoryFactoryQuery } from '@maestro/graphql';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { NotFound } from '../../../components/NotFound';
import { TableLoader } from '../../../components/TableLoader';
import { FieldsList } from './FieldsList';

export const StoryFactoryDetails: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetStoryFactoryQuery({ id: id! });

  if (isLoading) {
    return <TableLoader />;
  }

  if (!data?.storyFactory) {
    return <NotFound />;
  }

  return (
    <ScreenContainer>
      <HeaderContainer>
        <StyledHeaderRowContainer>
          <HeaderTitle>{data.storyFactory.title}</HeaderTitle>
          <ButtonGroup>
            <Link to={`/story-factory/${id}/edit`}>
              <Button variant="default">Edit</Button>
            </Link>
            <Link to={`/story-factory/${id}/new`}>
              <Button variant="primary">Create Story</Button>
            </Link>
          </ButtonGroup>
        </StyledHeaderRowContainer>
      </HeaderContainer>

      <SectionContainer>
        <SectionTitle>Prompts</SectionTitle>
        <SectionCard>
          <List>
            <ListHeader>
              <ListHeaderColumn width="30%">Prompt Name</ListHeaderColumn>
              <ListHeaderColumn flex>Slug</ListHeaderColumn>
            </ListHeader>
            <ListBody>
              <ListItem>
                <ListItemColumn width="30%">
                  One Character Prompt
                </ListItemColumn>
                <ListItemColumn>{data.storyFactory.promptSlug}</ListItemColumn>
              </ListItem>
              <ListItem>
                <ListItemColumn width="30%">
                  Two Character Prompt
                </ListItemColumn>
                <ListItemColumn>
                  {data.storyFactory.twoCharacterPromptSlug ?? '(None)'}
                </ListItemColumn>
              </ListItem>
              <ListItem>
                <ListItemColumn width="30%">
                  Scene Director Prompt
                </ListItemColumn>
                <ListItemColumn>
                  {data.storyFactory.sceneDirectorPromptSlug ?? '(None)'}
                </ListItemColumn>
              </ListItem>
              <ListItem>
                <ListItemColumn width="30%">
                  Creative Director Prompt
                </ListItemColumn>
                <ListItemColumn>
                  {data.storyFactory.creativeDirectorPromptSlug ?? '(None)'}
                </ListItemColumn>
              </ListItem>
            </ListBody>
          </List>
        </SectionCard>

        <SectionTitle>Form Fields</SectionTitle>
        <SectionCard>
          <FieldsList fields={data.storyFactory.fields} />
        </SectionCard>
      </SectionContainer>
    </ScreenContainer>
  );
};

const StyledHeaderRowContainer = styled(HeaderRowContainer)`
  align-items: center;
`;
