import { Button } from '@chakra-ui/react';
import { Icon, ListItem, ListItemColumn } from '@maestro/components';
import { FieldDefinition, FieldType } from '@maestro/graphql';
import { dimensions, rawDimensions } from '@maestro/styles';
import React from 'react';

type PropertyFormProps = {
  data: FieldDefinition;
  onRemove?: (data: FieldDefinition) => void;
  onEdit?: (data: FieldDefinition) => void;
};

const icons: Record<FieldType, string> = {
  [FieldType.Number]: 'number-type',
  [FieldType.Text]: 'text-type',
  [FieldType.Select]: 'enum-type',
};

export const FieldRow: React.FC<PropertyFormProps> = (props) => {
  const icon = icons[props.data.type];

  return (
    <ListItem onClick={() => props.onEdit?.(props.data)}>
      <ListItemColumn width="30%">
        <Icon name={icon} size={rawDimensions.size16} />
        {props.data.id}
      </ListItemColumn>
      <ListItemColumn flex>{props.data.name}</ListItemColumn>
      <ListItemColumn reverse width={dimensions.size120}>
        {props.onEdit && (
          <Button
            variant="inputButton"
            background="transparent"
            color="text.placeholder"
          >
            <Icon name="edit" size={rawDimensions.size20} />
          </Button>
        )}

        {props.onRemove && (
          <Button
            variant="inputButton"
            background="transparent"
            color="text.placeholder"
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove?.(props.data);
            }}
          >
            <Icon name="trash" size={rawDimensions.size20} />
          </Button>
        )}
      </ListItemColumn>
    </ListItem>
  );
};
