import { useRealtimeTokenMutation } from '@maestro/graphql';
import * as Ably from 'ably';
import { useState, useEffect, useCallback } from 'react';

export const useRealtimeClient = () => {
  const { mutateAsync } = useRealtimeTokenMutation();
  const [realtimeClient, setRealtimeClient] = useState<Ably.Realtime>();

  const getRealtimeToken = async () => {
    const data = await mutateAsync({});

    if (!data?.realtimeToken.token) {
      throw Error('Failed to generate realtime token');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...token } = data?.realtimeToken.token;

    return token;
  };

  const initializeRealtimeClient = useCallback(async (): Promise<void> => {
    const token = await getRealtimeToken();

    setRealtimeClient(
      new Ably.Realtime({
        token,
        authCallback: async (_, callback) => {
          try {
            callback(null, await getRealtimeToken());
          } catch (error) {
            callback(
              error instanceof Error
                ? error.message
                : 'Unknown error captured while requesting a new realtime token',
              null,
            );
          }
        },
      }),
    );
  }, []);

  useEffect(() => {
    initializeRealtimeClient().catch(() => void 0);
  }, []);

  return { realtimeClient };
};
